/* 2.01 - Template CSS Animations - Start
================================================== */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
}
.fadeInDown {
  animation-name: fadeInDown
}

// Phone Ringing
@keyframes phoneRinging {
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  20%, 32%, 44%, 56%, 68% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  23%, 35%, 47%, 59%, 71% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  26%, 38%, 50%, 62%, 74% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  29%, 41%, 53%, 65%, 77% {
    transform: rotate3d(0, 0, 1, -15deg);
  }
  80% {
    transform:rotate3d(0, 0, 1, 0deg);
  }
}
[class*="fa-phone"] {
  animation: phoneRinging 1.5s infinite linear;
}

@keyframes hueRotate {
  from {
    filter: hue-rotate(0);
  }
  to {
    filter: hue-rotate(360deg);
  }
}
.hueRotate {
  animation: hueRotate 3s linear infinite;
  background: linear-gradient(45deg, #000000,#002043, #34BBFC,  #00ACFF, #0078B2, #005B87, #003C7E,);
}

// Ripple Effect
@keyframes ripple {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
    transform: scale(1.75)
  }
}
.ripple {
  animation: ripple 1.5s linear infinite;
}

// Sipn 
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spin {
  animation: spin 5000ms infinite linear;
}

@keyframes spinReverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.spinReverse {
  animation: spinReverse 5000ms infinite linear;
}

//
@keyframes borderDashedAnimation {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}
.borderDashedAnimation {
  animation: borderDashedAnimation .5s infinite linear;
}

// Preloader
@keyframes preloaderSpin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.preloader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999999;
  background-color: var(--bs-dark);
}
.preloader .loader-circle {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 200px;
  height: 200px;
  direction: ltr;
  position: absolute;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .1);
}
.preloader .loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 200px;
  margin-left: -100px;
  margin-top: -100px;
  overflow: hidden;
  transform-origin: 100px 100px;
  animation: preloaderSpin 1.5s infinite linear;
}
.preloader .loader-line {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .44), rgba(255, 255, 255, 0));
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='rgba(255, 255, 255, 0.44)',endColorStr='rgba(255, 255, 255, 0)');
  background-color: initial;
  position: relative;
}
.preloader .loader-line:before {
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  z-index: 99;
  content: '';
  position: absolute;
  border-radius: inherit;
  background-color: var(--bs-dark);
}
.preloader .loader-logo {
  top: 50%;
  left: 0;
  right: 0;
  position: absolute;
  text-align: center;
  transform: translate(0%, -50%);
}
.preloader .loader-logo img {
  max-width: 65%;
}
/* 2.01 - Template CSS Animations - End
================================================== */